<template>
    <main>
        <div class="view-product-info">
            <div class="header-product-info">
                <div class="container-fluid">
                    <h1 class="header-product-info__title" v-html="getZiegel().fields.headline"></h1>
                    <p class="header-product-info__category" v-html="getZiegelTerm()"></p>
                    <div class="header-product-info__image">
                        <img :src="getImgFromID(getZiegel().fields.slideshow[0].id).url">
                    </div>
                </div>
            </div>
            <div class="section-product-info">
                <div class="container-fluid">
                    <vsa-list :tags='{
                     list: "div",
                    list__item: "div",
                    item__heading: "div",
                    heading__content: "div",
                    heading__icon: "span",
                    item__content: "div"
                    }'>
                        <vsa-item v-if="getZiegel().fields.daten.technische_details_tabelle">
                            <vsa-heading>
                                {{DachziegelData.data.options.lang.detail_specifications}}
                            </vsa-heading>
                            <vsa-content>
                                <div class="js-tabs" id="tabs">
                                    <ul class="js-tabs__header">
                                        <li v-if="getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel === null || getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel ===''">
                                            <a href="#" class="js-tabs__title">{{DachziegelData.data.options.lang.detail_specifications_column_fallback}}</a>
                                        </li>
                                        <li v-if="getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel">
                                            <a href="#" class="js-tabs__title"
                                               v-html="getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel"></a>
                                        </li>
                                        <li v-if="getZiegel().fields.daten.parent_technische_details_tabelle_spalte">
                                            <a href="#" class="js-tabs__title"
                                               v-html="getZiegel().fields.daten.parent_technische_details_tabelle_spalte_title"></a>
                                        </li>
                                    </ul>
                                    <div class="js-tabs__content">
                                        <div class="definition-list">
                                            <div v-for="list in getZiegel().fields.daten.technische_details_tabelle">
                                                <h3 v-html="list.spalte_1"></h3>
                                                <dl>
                                                    <dt v-if="getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel === null || getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel ===''">
                                                        {{DachziegelData.data.options.lang.detail_specifications_column_fallback}}
                                                    </dt>
                                                    <dt v-if="getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel"
                                                        v-html="getZiegel().fields.daten.parent_technische_details_tabellespalte_2_titel">
                                                    </dt>
                                                    <dd v-html="list.spalte_2"></dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="js-tabs__content"
                                         v-if="getZiegel().fields.daten.parent_technische_details_tabelle_spalte">
                                        <div class="definition-list">
                                            <div v-for="list in getZiegel().fields.daten.technische_details_tabelle">
                                                <h3 v-html="list.spalte_1"></h3>
                                                <dl>
                                                    <dt v-html="getZiegel().fields.daten.parent_technische_details_tabelle_spalte_title">
                                                    </dt>
                                                    <dd v-html="list.spalte_3"></dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vsa-content>
                        </vsa-item>
                        <vsa-item v-if="getZiegel().fields.daten.latten_abstand_first_laf_in_mm">
                            <vsa-heading>
                                {{DachziegelData.data.options.lang.detail_latten_abstand_first}}
                            </vsa-heading>
                            <vsa-content>
                                <h3 class="data-table__heading">{{DachziegelData.data.options.lang.detail_latten_abstand_first_laf}}</h3>
                                <div class="table-responsive" id="table-responsive-4">
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="row" rowspan="2">{{DachziegelData.data.options.lang.detail_ridge_tile}}</th>
                                            <th colspan="8">{{DachziegelData.data.options.lang.detail_roof_pitch}}</th>
                                        </tr>
                                        <tr>
                                            <th>15°</th>
                                            <th>20°</th>
                                            <th>25°</th>
                                            <th>30°</th>
                                            <th>35°</th>
                                            <th>40°</th>
                                            <th>45°</th>
                                            <th>50°</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="list in getZiegel().fields.daten.latten_abstand_first_laf_in_mm">
                                            <th scope="row" v-html="list.bezeichnung"></th>
                                            <td v-html="list[15]"></td>
                                            <td v-html="list[20]"></td>
                                            <td v-html="list[25]"></td>
                                            <td v-html="list[30]"></td>
                                            <td v-html="list[35]"></td>
                                            <td v-html="list[40]"></td>
                                            <td v-html="list[45]"></td>
                                            <td v-html="list[50]"></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div @click="scrollTable('table-responsive-4')" class="btn btn-link-scroll">{{DachziegelData.data.options.lang.detail_roof_pitch_more}} <span class="icon icon-chevron-next"></span></div>

                                <h3 class="data-table__heading">{{DachziegelData.data.options.lang.detail_latten_abstand_first_fla}}</h3>
                                <div class="table-responsive" id="table-responsive-3">
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="row" rowspan="2">{{DachziegelData.data.options.lang.detail_ridge_tile}}</th>
                                            <th colspan="8">{{DachziegelData.data.options.lang.detail_roof_pitch}}</th>
                                        </tr>
                                        <tr>
                                            <th>15°</th>
                                            <th>20°</th>
                                            <th>25°</th>
                                            <th>30°</th>
                                            <th>35°</th>
                                            <th>40°</th>
                                            <th>45°</th>
                                            <th>50°</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="list in getZiegel().fields.daten.first_latten_abstand_fla_in_mm">
                                            <th scope="row" v-html="list.bezeichnung"></th>
                                            <td v-html="list[15]"></td>
                                            <td v-html="list[20]"></td>
                                            <td v-html="list[25]"></td>
                                            <td v-html="list[30]"></td>
                                            <td v-html="list[35]"></td>
                                            <td v-html="list[40]"></td>
                                            <td v-html="list[45]"></td>
                                            <td v-html="list[50]"></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div @click="scrollTable('table-responsive-3')" class="btn btn-link-scroll">{{DachziegelData.data.options.lang.detail_roof_pitch_more}} <span class="icon icon-chevron-next"></span></div>

                            </vsa-content>
                        </vsa-item>
                        <vsa-item v-if="getZiegel().fields.daten.parent_latten_abstand_first_laf_firstanschluss_lufterziegel_fal_in_mm">
                            <vsa-heading>
                                {{DachziegelData.data.options.lang.detail_firstanschluss_luefterziegel}}
                            </vsa-heading>
                            <vsa-content>
                                <h3 class="data-table__heading">
                                    {{DachziegelData.data.options.lang.detail_firstanschluss_luefterziegel_laf}}
                                </h3>
                                <div class="table-responsive" id="table-responsive-1">
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="row" rowspan="2">{{DachziegelData.data.options.lang.detail_ridge_tile}}</th>
                                            <th colspan="9">{{DachziegelData.data.options.lang.detail_roof_pitch}}</th>
                                        </tr>
                                        <tr>
                                            <th>15°</th>
                                            <th>20°</th>
                                            <th>25°</th>
                                            <th>30°</th>
                                            <th>35°</th>
                                            <th>40°</th>
                                            <th>45°</th>
                                            <th>50°</th>
                                            <th>55°</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="list in getZiegel().fields.daten.parent_latten_abstand_first_laf_firstanschluss_lufterziegel_fal_in_mm">
                                            <th scope="row" v-html="list.bezeichnung"></th>
                                            <td v-html="list[15]"></td>
                                            <td v-html="list[20]"></td>
                                            <td v-html="list[25]"></td>
                                            <td v-html="list[30]"></td>
                                            <td v-html="list[35]"></td>
                                            <td v-html="list[40]"></td>
                                            <td v-html="list[45]"></td>
                                            <td v-html="list[50]"></td>
                                            <td v-html="list[55]"></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div @click="scrollTable('table-responsive-1')" class="btn btn-link-scroll">{{DachziegelData.data.options.lang.detail_roof_pitch_more}} <span class="icon icon-chevron-next"></span></div>

                                <h3 class="data-table__heading">
                                    {{DachziegelData.data.options.lang.detail_firstanschluss_luefterziegel_fla}}
                                </h3>
                                <div class="table-responsive" id="table-responsive-2">
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="row" rowspan="2">{{DachziegelData.data.options.lang.detail_ridge_tile}}</th>
                                            <th colspan="9">{{DachziegelData.data.options.lang.detail_roof_pitch}}</th>
                                        </tr>
                                        <tr>
                                            <th>15°</th>
                                            <th>20°</th>
                                            <th>25°</th>
                                            <th>30°</th>
                                            <th>35°</th>
                                            <th>40°</th>
                                            <th>45°</th>
                                            <th>50°</th>
                                            <th>55°</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="list in getZiegel().fields.daten.parent_first_latten_abstand_fla__firstanschluss_lufterziegel_fal_in_mm">
                                            <th scope="row" v-html="list.bezeichnung"></th>
                                            <td v-html="list[15]"></td>
                                            <td v-html="list[20]"></td>
                                            <td v-html="list[25]"></td>
                                            <td v-html="list[30]"></td>
                                            <td v-html="list[35]"></td>
                                            <td v-html="list[40]"></td>
                                            <td v-html="list[45]"></td>
                                            <td v-html="list[50]"></td>
                                            <td v-html="list[55]"></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div @click="scrollTable('table-responsive-2')" class="btn btn-link-scroll">{{DachziegelData.data.options.lang.detail_roof_pitch_more}} <span class="icon icon-chevron-next"></span></div>
                            </vsa-content>
                        </vsa-item>
                        <vsa-item v-if="getZiegel().fields.daten.parent_latt_abstand_first_laf_in_mm_fur_pultfirst_universal_90">
                        <vsa-heading>
                          {{DachziegelData.data.options.lang.detail_latten_abstand_first_pultfirst}}
                        </vsa-heading>
                        <vsa-content>
                          <h3 class="data-table__heading">{{DachziegelData.data.options.lang.detail_latten_abstand_first_pultfirst_laf}}</h3>
                          <div class="table-responsive" id="table-responsive-5">
                            <table class="table table-striped">
                              <thead>
                              <tr>
                                <th scope="row" rowspan="2">{{DachziegelData.data.options.lang.detail_ridge_tile}}</th>
                                <th colspan="9">{{DachziegelData.data.options.lang.detail_roof_pitch}}</th>
                              </tr>
                              <tr>
                                <th>15°</th>
                                <th>20°</th>
                                <th>25°</th>
                                <th>30°</th>
                                <th>35°</th>
                                <th>40°</th>
                                <th>45°</th>
                                <th>50°</th>
                                <th>55°</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="list in getZiegel().fields.daten.parent_latt_abstand_first_laf_in_mm_fur_pultfirst_universal_90">
                                <th scope="row" v-html="list.bezeichnung"></th>
                                <td v-html="list[15]"></td>
                                <td v-html="list[20]"></td>
                                <td v-html="list[25]"></td>
                                <td v-html="list[30]"></td>
                                <td v-html="list[35]"></td>
                                <td v-html="list[40]"></td>
                                <td v-html="list[45]"></td>
                                <td v-html="list[50]"></td>
                                <td v-html="list[55]"></td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div @click="scrollTable('table-responsive-5')" class="btn btn-link-scroll">{{DachziegelData.data.options.lang.detail_roof_pitch_more}} <span class="icon icon-chevron-next"></span></div>

                          <h3 class="data-table__heading">
                            {{DachziegelData.data.options.lang.detail_latten_abstand_first_pultfirst_fla}}
                          </h3>
                          <div class="table-responsive" id="table-responsive-6">
                            <table class="table table-striped">
                              <thead>
                              <tr>
                                <th scope="row" rowspan="2">{{DachziegelData.data.options.lang.detail_ridge_tile}}</th>
                                <th colspan="9">{{DachziegelData.data.options.lang.detail_roof_pitch}}</th>
                              </tr>
                              <tr>
                                <th>15°</th>
                                <th>20°</th>
                                <th>25°</th>
                                <th>30°</th>
                                <th>35°</th>
                                <th>40°</th>
                                <th>45°</th>
                                <th>50°</th>
                                <th>55°</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="list in getZiegel().fields.daten.parent_first_latten_abstand_fla_in_mm_fur_pultfirst_universal_90">
                                <th scope="row" v-html="list.bezeichnung"></th>
                                <td v-html="list[15]"></td>
                                <td v-html="list[20]"></td>
                                <td v-html="list[25]"></td>
                                <td v-html="list[30]"></td>
                                <td v-html="list[35]"></td>
                                <td v-html="list[40]"></td>
                                <td v-html="list[45]"></td>
                                <td v-html="list[50]"></td>
                                <td v-html="list[55]"></td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div @click="scrollTable('table-responsive-6')" class="btn btn-link-scroll">{{DachziegelData.data.options.lang.detail_roof_pitch_more}} <span class="icon icon-chevron-next"></span></div>
                        </vsa-content>
                      </vsa-item>
                        <vsa-item v-if="getZiegel().fields.daten.parent_technische_bilder_v2"
                                  v-for="item in getZiegel().fields.daten.parent_technische_bilder_v2">
                            <vsa-heading>
                                <span v-html="item.titel">

                                </span>
                            </vsa-heading>
                            <vsa-content>
                                <div class="image-thumbnail">
                                    <figure>
                                        <img :src="getImgFromID(item.bild.id).url" class="img-fluid">
                                        <figcaption v-if="item.bild.alt" v-html="item.bild.alt"></figcaption>
                                    </figure>
                                </div>
                            </vsa-content>
                        </vsa-item>
                    </vsa-list>
                </div>
            </div>
            <div class="section-product-links">
                <div class="container-fluid">
                    <ul class="button-list">
                        <li>
                            <button href="#" class="btn btn-primary"
                                    @click="openURL(DachziegelData.data.options.current_base_url+'/produkte/'+getZiegel().WP_Post.post_name+'/')">
                                {{DachziegelData.data.options.lang.detail_button_browser_product}}
                              <svg class="icon icon--after" aria-hidden="true">
                                <use xlink:href="#icon-arrow-link--small"></use>
                              </svg>
                            </button>
                        </li>
                        <li>
                            <button href="#" class="btn btn-primary"
                                    @click="openURL(DachziegelData.data.options.current_base_url+'/zubehoer/ziegel/'+getZiegel().WP_Post.post_name+'/')">
                                {{DachziegelData.data.options.lang.detail_button_browser_accessories}}
                              <svg class="icon icon--after" aria-hidden="true">
                                <use xlink:href="#icon-arrow-link--small"></use>
                              </svg>
                            </button>
                        </li>
                      <li v-if="DachziegelData.data.options.current_lang === 'de'">
                        <button href="#" class="btn btn-primary"
                                @click="openURL('https://www.dachziegel.de/produkte/'+getZiegel().WP_Post.post_name+'/#collapseDownloads')">
                          {{DachziegelData.data.options.lang.detail_button_browser_downloads}}
                          <svg class="icon icon--after" aria-hidden="true">
                            <use xlink:href="#icon-arrow-link--small"></use>
                          </svg>
                        </button>
                      </li>
                        <li v-if="DachziegelData.data.options.current_lang === 'de'">
                            <button href="#" class="btn btn-primary"
                                    @click="openURL('https://www.dachziegel.de/windsogberechner/')">
                                {{DachziegelData.data.options.lang.detail_button_browser_wind}}
                              <svg class="icon icon--after" aria-hidden="true">
                                <use xlink:href="#icon-arrow-link--small"></use>
                              </svg>
                            </button>
                        </li>
                        <li v-if="DachziegelData.data.options.current_lang === 'de'">
                            <button href="#" class="btn btn-primary"
                                    @click="openURL('https://www.dachziegel.de/schneelastberechner/')">
                                {{DachziegelData.data.options.lang.detail_button_browser_snow}}
                              <svg class="icon icon--after" aria-hidden="true">
                                <use xlink:href="#icon-arrow-link--small"></use>
                              </svg>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";
    import {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    } from 'vue-simple-accordion';

    export default {
        name: 'ZiegelListe',
        mixins: [DachziegelDataMixin],
        components: {
            VsaList,
            VsaItem,
            VsaHeading,
            VsaContent,
            VsaIcon
        },
        mounted: function () {
            this.$DKFuctions.headerShowGoBackShow()
            this.$loadScript("./assets/js/vanilla-js-tabs.min.js")
                .then(() => {
                    var tabs = new Tabs({
                        elem: "tabs",
                        open: 0
                    })
                })
        },
        methods: {
            scrollTable: function ($id) {
                document.getElementById($id).scrollLeft = 300
                return false
            },
            openURL: function ($url) {
                window.open(encodeURI($url), '_system', 'location=yes')
                return false
            },
            routeZiegelID: function () {
                return this.$route.params.ziegelID
            },
            getZiegel: function (ziegelID) {
                return this.$DKFuctions.getZiegelFromID(this.routeZiegelID())
            },
            getImgFromID: function (imgID) {
                return this.$DKFuctions.getImgFromID(imgID)
            },
            getZiegelTerm: function () {
                const term = this.$DKFuctions.findZiegelTermFromTaxonomy(this.routeZiegelID(), 'ziegel_kategorie');
                if (term) {
                    return term.name
                }
            }
        }
    }
</script>
